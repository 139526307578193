import * as THREE from "three";
import {HorizontalBlurShader} from "three/examples/jsm/shaders/HorizontalBlurShader";
import {VerticalBlurShader} from "three/examples/jsm/shaders/VerticalBlurShader";

export default class Vue3DAOShadow {

    constructor(_scene, _renderer, _cameraHeight) {

        this.shadow =  {
            blur: 0,
            darkness: 0.4,
            opacity: 0.6
        }

        this.plane = {
            color: '#ffffff',
            opacity: 0
        }

        this.scene = _scene;
        this.renderer = _renderer;

        const PLANE_WIDTH = 400;
        const PLANE_HEIGHT = 400;

        let shadowGroup = new THREE.Group();
        shadowGroup.position.y = 0.01;
        this.scene.add( shadowGroup );

        this.renderTarget = new THREE.WebGLRenderTarget( 2048*2, 2048*2 );
        this.renderTarget.texture.generateMipmaps = false;

        // the render target that we will use to blur the first render target
        this.renderTargetBlur = new THREE.WebGLRenderTarget( 2048*2, 2048*2 );
        this.renderTargetBlur.texture.generateMipmaps = false;

        // make a plane and make it face up
        const planeGeometry = new THREE.PlaneGeometry( PLANE_WIDTH, PLANE_HEIGHT ).rotateX( Math.PI / 2 );
        const planeMaterial = new THREE.MeshBasicMaterial( {
            map: this.renderTarget.texture,
            opacity: this.shadow.opacity,
            transparent: true,
            depthWrite: false,
        } );
        let plane = new THREE.Mesh( planeGeometry, planeMaterial );
        // make sure it's rendered after the fillPlane
        plane.renderOrder = 1;
        plane.scale.y = - 1;
        shadowGroup.add( plane );

        this.blurPlane = new THREE.Mesh( planeGeometry );
        this.blurPlane.visible = false;
        shadowGroup.add( this.blurPlane );

        // the plane with the color of the ground
        const fillPlaneMaterial = new THREE.MeshBasicMaterial( {
            color: this.plane.color,
            opacity: this.plane.opacity,
            transparent: true,
            depthWrite: false,
        } );
        let fillPlane = new THREE.Mesh( planeGeometry, fillPlaneMaterial );
        fillPlane.rotateX( Math.PI );
        shadowGroup.add( fillPlane );

        this.shadowCamera = new THREE.OrthographicCamera( - PLANE_WIDTH / 2, PLANE_WIDTH / 2, PLANE_HEIGHT / 2, - PLANE_HEIGHT / 2, 0, _cameraHeight );
        this.shadowCamera.rotation.x = Math.PI / 2; // get the camera to look up
        shadowGroup.add( this.shadowCamera );

        let me = this;
        // like MeshDepthMaterial, but goes from black to transparent
        this.depthMaterial = new THREE.MeshDepthMaterial();
        this.depthMaterial.userData.darkness = { value: this.shadow.darkness };
        this.depthMaterial.onBeforeCompile = function ( shader ) {

            shader.uniforms.darkness = me.depthMaterial.userData.darkness;
            shader.fragmentShader = /* glsl */`
						uniform float darkness;
						${shader.fragmentShader.replace(
                'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
                'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
            )}
					`;

        };

        this.depthMaterial.depthTest = false;
        this.depthMaterial.depthWrite = false;

        this.horizontalBlurMaterial = new THREE.ShaderMaterial( HorizontalBlurShader );
        this.horizontalBlurMaterial.depthTest = false;

        this.verticalBlurMaterial = new THREE.ShaderMaterial( VerticalBlurShader );
        this.verticalBlurMaterial.depthTest = false;
        /*
        if(this.debug)
        {
            let cameraHelper = new THREE.CameraHelper( this.shadowCamera );
            this.scene.add(cameraHelper);
        }
        */
    }

    blurShadow( amount ) {

        this.blurPlane.visible = true;

        // blur horizontally and draw in the renderTargetBlur
        this.blurPlane.material = this.horizontalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTarget.texture;
        this.horizontalBlurMaterial.uniforms.h.value = amount * 1 / 256;

        this.renderer.setRenderTarget( this.renderTargetBlur );
        this.renderer.render( this.blurPlane, this.shadowCamera );

        // blur vertically and draw in the main renderTarget
        this.blurPlane.material = this.verticalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTargetBlur.texture;
        this.verticalBlurMaterial.uniforms.v.value = amount * 1 / 256;

        this.renderer.setRenderTarget( this.renderTarget );
        this.renderer.render( this.blurPlane, this.shadowCamera );

        this.blurPlane.visible = false;

    }

    render()
    {
        // remove the background
        const initialBackground = this.scene.background;
        this.scene.background = null;

        // force the depthMaterial to everything
        this.scene.overrideMaterial = this.depthMaterial;

        // set renderer clear alpha
        const initialClearAlpha = this.renderer.getClearAlpha();
        this.renderer.setClearAlpha( 0 );

        // render to the render target to get the depths
        this.renderer.setRenderTarget( this.renderTarget );
        this.renderer.render( this.scene, this.shadowCamera );

        // and reset the override material
        this.scene.overrideMaterial = null;

        this.blurShadow( this.shadow.blur );

        // a second pass to reduce the artifacts
        // (0.4 is the minimum blur amout so that the artifacts are gone)
        this.blurShadow( this.shadow.blur * 0.4 );

        // reset and render the normal scene
        this.renderer.setRenderTarget( null );
        this.renderer.setClearAlpha( initialClearAlpha );
        this.scene.background = initialBackground;
    }
}