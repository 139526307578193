import * as THREE from 'three';

export default class Vue3DDropShadow {

    constructor(_scene) {

        let shadowLight = new THREE.DirectionalLight( 0xffffff, 0 );
        shadowLight.position.set( 0, 300, 0 );
        shadowLight.lookAt(0, 0, 0);
        shadowLight.shadow.mapSize.width = 2048;
        shadowLight.shadow.mapSize.height = 2048;
        shadowLight.shadow.radius = 7;
        shadowLight.shadow.bias = 0.75;
        shadowLight.shadow.blurSamples = 10;
        shadowLight.castShadow = true;
        shadowLight.shadow.camera.near = 50;
        shadowLight.shadow.camera.far = 1500;
        let d = 500;
        shadowLight.shadow.camera.left = d;
        shadowLight.shadow.camera.right = -d;
        shadowLight.shadow.camera.top = d;
        shadowLight.shadow.camera.bottom = -d;
        _scene.add( shadowLight );

        const geometry = new THREE.PlaneGeometry( 500, 500 );
        geometry.rotateX( - Math.PI / 2 );

        const material = new THREE.ShadowMaterial({
            color: 0x000000,
            depthWrite: false,
            opacity: 0.01,
            transparent: true,
        });
        material.opacity = 0.03;

        const plane = new THREE.Mesh( geometry, material );
        plane.receiveShadow = true;
        _scene.add( plane );
    }

}