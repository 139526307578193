import Api from "./Api";

export default class Api3dvue extends Api{

    constructor(){
        super();
        let me=this;
    }


    /**
     * Pour récupérer un model
     * @param {String} name
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getFile3d(name,cbSuccess,cbError){
        this._call(
            "3dvue","get-file3d",
            {
                name:name,
            },
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour récupérer une liste de material
     * @param {Object} materialList
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getMaterialList(materialList,cbSuccess,cbError){
        this._call(
            "3dvue","get-materialList",
            {
                materialList:JSON.stringify(materialList),
            },
            cbSuccess,
            cbError
        );
    }

}