//import Nav from "../nav/Nav";

$body.on("click","[api-click]",function(e){

    let $clicked=$(this);
    let action=$(this).attr("api-click");
    let uid=null;
    let $article=null;
    let file=null;

    e.preventDefault();
    e.stopPropagation();


    switch (action) {

        default:
            alert(`api-click Action ${action} non prise en charge`);
    }
});


$body.on("submit","[api-submit]",function(e){
    let $form=$(this);
    let action=$(this).attr("api-submit");
    e.preventDefault();
    e.stopPropagation();

    switch (action) {
        case "todo":
        default:
            alert(`Action ${action} non prise en charge par api-submit`);

    }
});