import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import gsap from "gsap";

export default class Vue3DControls extends OrbitControls {

    constructor(_camera, _renderer) {

        super(_camera, _renderer.domElement);
        this.camera = _camera;

        this.camera.position.x = 0;
        this.camera.position.y = 150;
        this.camera.position.z = 250;

        this.minDistance = 50;

        this.enableDamping = true;
        this.dampingFactor = 0.08;
        this.maxPolarAngle = Math.PI/2;// + Math.PI/20;
        this.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.PAN,
            RIGHT: THREE.MOUSE.PAN
        }

        this.target.set( 0, 60, 0 );
    }

    resetCameraToObject(object, scene)
    {
        let bb = new THREE.Box3().setFromObject(object, true);
        let size = bb.getSize(new THREE.Vector3());
        /*
        const geometry = new THREE.BoxGeometry( size.x, size.y, size.z );
        geometry.translate( 0, size.y/2, 0 );
        const wireframe = new THREE.WireframeGeometry( geometry );

        const line = new THREE.LineSegments( wireframe );
        line.material.depthTest = false;
        line.material.opacity = 1;
        line.material.transparent = true;
        scene.add( line );
        */

        const vFOV = this.camera.fov * Math.PI / 180;
        let ratio = size.x/size.y;
        //let distance = this.distanceToFitObjectToView(this.camera.aspect, vFOV, size.x, size.y);

        //viewport large
        //objet haut
        let distance = this._distanceToFitObjectInView(size.y, vFOV);

        //objet large
        if(size.x > size.y || this.camera.aspect < ratio)
        {
            distance = this._distanceToFitObjectInView(size.x, this.vfovToHfov(vFOV, this.camera.aspect));
        }

        let factor = 1.5;
        if(Math.abs(1-ratio) < 0.09) factor = 1.8;

        distance *= factor;

        //console.log("screen ratio : " + this.camera.aspect);
        //console.log("object ratio : " + ratio);

        this.minDistance = distance/2;
        this.maxDistance = distance * 1.5;

        let target = new THREE.Object3D();

        this.camera.position.x = target.position.x = 0;
        this.camera.position.y = target.position.y = 0;
        this.camera.position.z = target.position.z = 0;

        target.rotation.x = -50 * Math.PI/360;
        target.rotation.y = 60 * Math.PI/360;
        target.translateZ( distance );

        this.camera.rotation.x = -50 * Math.PI/360;
        this.camera.rotation.y = 30 * Math.PI/360;
        this.camera.translateZ( distance );

        let me = this;
        gsap.to( this.camera.position, {
            duration: 1.5,
            x: target.position.x,
            y: target.position.y,
            z: target.position.z
        } );

        this.target.set( 0, size.y/2, 0 );
    }



    /**
     * Convert vertical field of view to horizontal field of view, given an aspect
     * ratio. See https://arstechnica.com/civis/viewtopic.php?f=6&t=37447
     *
     * @param vfov - The vertical field of view.
     * @param aspect - The camera aspect ratio, which is generally width/height of the viewport.
     * @returns - The horizontal field of view.
     */
    vfovToHfov(vfov, aspect) {
        const {tan, atan} = Math
        return atan(aspect * tan(vfov / 2)) * 2
    }

    /**
     * Get the distance from the camera to fit an object in view by either its
     * horizontal or its vertical dimension.
     *
     * @param size - This should be the width or height of the object to fit.
     * @param fov - If `size` is the object's width, `fov` should be the horizontal
     * field of view of the view camera. If `size` is the object's height, then
     * `fov` should be the view camera's vertical field of view.
     * @returns - The distance from the camera so that the object will fit from
     * edge to edge of the viewport.
     */
    _distanceToFitObjectInView(size, fov) {
        const {tan} = Math
        return size / (2 * tan(fov / 2))
    }

    distanceToFitObjectToView(
        cameraAspect,
        cameraVFov,
        objWidth,
        objHeight
    ) {
        const objAspect = objWidth / objHeight
        const cameraHFov = this.vfovToHfov(cameraVFov, cameraAspect)

        let distance = 0

        if (objAspect > cameraAspect) {
            distance = this._distanceToFitObjectInView(objHeight, cameraVFov)
            console.log("width");
        } else if (objAspect <= cameraAspect) {
            distance = this._distanceToFitObjectInView(objWidth, cameraHFov)
            console.log("height");
        }

        return distance
    }

}