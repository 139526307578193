import * as THREE from 'three';
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";


export default class Vue3DRenderer extends THREE.WebGLRenderer {



    constructor(_container) {
        super({antialias: true, alpha:true, stencil:true});
        this.setSize( window.innerWidth, window.innerHeight );
        this.shadowMap.enabled = true;
        this.shadowMap.type = THREE.VSMShadowMap;
        this.toneMapping = THREE.LinearToneMapping;

        this.outputColorSpace = THREE.SRGBColorSpace;

        document.querySelector(_container).appendChild( this.domElement );
        this.domElement.style.background = "radial-gradient(#ebebeb, #ebebeb)";
    }

    loadHDR(_scene, _map, _cbSuccess, _cbProgress, _context)
    {
        new RGBELoader()
            .load( _map, function ( texture ) {

                texture.mapping = THREE.EquirectangularReflectionMapping;
                texture.colorSpace = THREE.LinearSRGBColorSpace;
                texture.magFilter = THREE.LinearFilter;
                //_scene.background = texture;
                _scene.environment = texture;

                if(_cbSuccess) _cbSuccess(_context);

            }, (e)=>{
                if(_cbProgress) _cbProgress(_context, "initialize", Math.round(e.loaded/e.total*100));
            });
    }


    update(_scene, _camera)
    {
        this.render( _scene, _camera );
    }

    resize()
    {
        this.setSize( window.innerWidth, window.innerHeight );
    }
}