import Slick from "./organisms/Slick.js";
import Api from "./api/Api";
import Api3dvue from "./api/Api3dvue";
import Vue3D from "./3dvue/Vue3D";
import {System} from "three/examples/jsm/libs/ecsy.module";

export default class Site {
    constructor() {
        /**
         *
         * @type {Site}
         */
        let me = this;
        me._initListeners();
        //---------------------go------------------------------------------
        me.resizeStage();
        me.onDomChange();
        Site.navActive();

        //api
        require("./api/api-click.js");
        window.api=new Api();
        window.api.Api3dvue=new Api3dvue();

        let API_3DVUE = {
            EVENT:{
                READY:"READY",
                LOAD_COMPLETE:"LOAD_COMPLETE"
            },
            ACTION:{
                LOAD_MODEL:"LOAD_MODEL",
                CHANGE_MATERIAL:"CHANGE_MATERIAL",
                START_RENDERER:"START_RENDERER",
                PAUSE_RENDERER:"PAUSE_RENDERER",
                RESET_CAMERA:"RESET_CAMERA"
            }
        }


        me.vue3d = new Vue3D("#ModelViewer", LayoutVars.rootPath);

        me.vue3d.on(Vue3D.READY, ()=>{
            parent.postMessage({event:API_3DVUE.EVENT.READY}, "*");
        });

        me.vue3d.on(Vue3D.LOAD_COMPLETE, ()=>{
            parent.postMessage({event:API_3DVUE.EVENT.LOAD_COMPLETE}, "*");
        });

        window.addEventListener("message", function(e) {
            switch (e.data.action)
            {
                case API_3DVUE.ACTION.LOAD_MODEL:
                    me.loadModel(e.data.modelName, (e.data.options)?e.data.options:null);
                    break;
                case API_3DVUE.ACTION.CHANGE_MATERIAL:
                    me.loadMaterials(e.data.materials);
                    break;
                case API_3DVUE.ACTION.START_RENDERER:
                    me.vue3d.startRenderer();
                    break;
                case API_3DVUE.ACTION.PAUSE_RENDERER:
                    me.vue3d.stopRenderer();
                    break;
                case API_3DVUE.ACTION.RESET_CAMERA:
                    me.vue3d.resetCamera();
                    break;
            }
        });
    }

    /**
     *
     * @private
     */
    _initListeners() {

        let me=this;

        require("./layout/NavMenu");
        NavMenu.__init();
        require("./components/data-zoom-img");
        require("./components/data-is-lang");
        require("./organisms/data-cards-container.js");

        Slick.initFromDom();
        //require("./blocks/FormContact");
        //FormContact.initFromDom();

        //ferme le menu quand on change d'url
        $body.on(EVENTS.HISTORY_CHANGE_URL,function(){
            $body.attr("data-page-transition-state","start");
            //stope en attendant que la transition soit finie
            PovHistory.readyToinject=false;
            //dit qu'on est prêt à afficher la page (s'assure qu'on reste au moins une seconde sur l'écran de transition)
            setTimeout(function(){
                PovHistory.readyToinject=true;
            },500);
            NavMenu.close();
        });

        //changement d'url et HTML injecté
        $body.on(EVENTS.HISTORY_CHANGE_URL_LOADED_INJECTED,function(){
            $body.attr("data-page-transition-state","end");
            me.onDomChange();
            //scroll top
            $(window).scrollTop(0);
            Site.navActive();

            if(typeof gtag !== 'undefined' && LayoutVars.googleAnalyticsId){
                //hit google analytics
                gtag('config', LayoutVars.googleAnalyticsId, {'page_path': location.pathname});
            }

        });

        STAGE.on(EVENTS.RESIZE,function(){
            me.resizeStage();
        });
        $body.on(Pov.events.DOM_CHANGE,function(){
            me.onDomChange();
        });
    }

    /**
     * Selectionne / déselectionne l'item de nav de la page en cours
     */
    static navActive(){
        $("[data-href-uid]").removeClass("active");
        $("[data-href-uid='"+PovHistory.currentPageInfo.uid+"']").addClass("active");
    }

    /**
     * Adapte des élements à l'écran
     */
    resizeStage(){
        //ou pas :)
    }

    /**
     * Initialisations d'objets dom
     */
    onDomChange(){
        Slick.initFromDom();
        //ou pas :)
    }

    loadModel(name, options)
    {
        let me = this;

        if(options.materials)
        {
            api.Api3dvue.getMaterialList(options.materials, (msg, html, json)=>{
                options.materials = json;
                api.Api3dvue.getFile3d(name, (msg, html, json)=>{
                    if(me.vue3d){
                        me.vue3d.loadModel(json.path, options);
                    }
                }, ()=>{
                    //todo display error model n'existe pas
                });

            }, ()=>{
                //todo display error material n'existe pas
            });
        } else {
            api.Api3dvue.getFile3d(name, (msg, html, json)=>{
                if(me.vue3d){
                    me.vue3d.loadModel(json.path, options);
                }
            }, ()=>{
                //todo display error model n'existe pas
            });
        }
    }

    loadMaterials(materials)
    {
        let me = this;
        api.Api3dvue.getMaterialList(materials, (msg, html, json)=> {
            me.vue3d.setMaterials(json);
        }, ()=>{
            //todo display error model n'existe pas
        });
    }
}