import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min'
import Stats from "stats.js";
import {EventEmitter} from "events";

export default class Vue3DConfigurator extends EventEmitter {

    vue3d = null;
    gui = null;
    stats = null;

    api = {
        displayStats: true,
        displayEnvMap:false,
        map:"",
        mapExposure:1,
        model:"",
        mesh:"",
        material:""
    }

    materialFolder = null;
    materialCtrl = null;
    materialData = null;

    constructor(_vue3d) {
        super();

        let me = this;
        me.vue3d = _vue3d;
        me.gui = new GUI();

        me.statsFolder();
        me.hdrFolder();
    }

    statsFolder()
    {
        let me = this;

        me.stats = new Stats();
        me.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild( me.stats.dom );

        const folder = this.gui.addFolder( 'Stats');
        folder.add(me.api, 'displayStats').onChange(function (display) {
            if(display) document.body.appendChild( me.stats.dom );
            else document.body.removeChild( me.stats.dom );
        });
    }

    hdrFolder()
    {
        let me = this;

        let url=`${LayoutVars.rootUrl}/api/3dvue/get-envmaps`;

        $.ajax({
            dataType: "json",
            url: url,
            method: "post",
            success: function (response) {

                let maps = [""];
                response.json.maps.forEach(function (map, id)
                {
                    maps.push(map.name);
                });

                const folder = me.gui.addFolder( 'HDR');
                folder.add( me.api, 'map' ).options( maps ).onChange(function (selection) {
                    const mapInfo = response.json.maps.find(({ name }) => name === selection);
                    let path = me.vue3d.httpRootPath+"/"+mapInfo.path+(this.noCach?"?no-cach="+Math.random():"");
                    me.vue3d.renderer.loadHDR(me.vue3d.scene, path, ()=>{
                        if(me.vue3d.scene.background != null ) me.vue3d.scene.background = me.vue3d.scene.environment;
                    }, null, null);
                });

                folder.add(me.api, 'displayEnvMap').onChange(function (display) {
                    if(display) me.vue3d.scene.background = me.vue3d.scene.environment;
                    else me.vue3d.scene.background = null;
                });

                folder.add(me.api, 'mapExposure', 0, 2, 0.01).onChange((value)=>{
                    me.vue3d.renderer.toneMappingExposure = value;
                });

                me.modelFolder();
            },
            error: function (response) {
                console.error(response);
            }
        });
    }

    modelFolder()
    {
        let me = this;

        let url=`${LayoutVars.rootUrl}/api/3dvue/get-3dmodels`;

        $.ajax({
            dataType: "json",
            url: url,
            method: "post",
            success: function (response) {

                let models = [""];
                response.json.file3d.forEach(function (obj, id)
                {
                    models.push(obj.name);
                });

                const folder = me.gui.addFolder( 'Models');
                folder.add( me.api, 'model' ).options( models ).onChange(function (selection) {
                    const modelInfo = response.json.file3d.find(({ name }) => name === selection);

                    me.vue3d.loadModel(modelInfo.path, null, me.meshList.bind(me));

                    if(me.materialFolder) me.materialFolder.destroy();
                });

            },
            error: function (response) {
                console.error(response);
            }
        });
    }


    meshList()
    {
        let me = this;
        me.api.mesh = "";
        let mesh = [""];
        me.vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.includes(identifier)) return;
                mesh.push(identifier);
            }
        });

        mesh.sort();

        me.materialFolder = me.gui.addFolder( 'Materials' );
        let meshCtrl = me.materialFolder.add( this.api, 'mesh' ).options( mesh );

        me.materialList();

        meshCtrl.onChange( function (selection) {
            me.api.material = "";
            me.materialCtrl.setValue("");

            me.vue3d.model.traverse(function (child) {
                if(child.isMesh) {
                    if(child.name.includes(me.api.mesh))
                    {
                        me.materialCtrl.setValue(child.material.name);
                    }
                }
            });
        });
    }


    materialList()
    {
        let me = this;

        if(!me.materialData)
        {
            let url=`${LayoutVars.rootUrl}/api/3dvue/get-materials`;

            $.ajax({
                dataType: "json",
                url: url,
                method: "post",
                success: function (response) {

                    me.materialData = response.json.materials;
                    me.displayMaterialList();


                },
                error: function (response) {
                    console.error(response);
                }
            });
        } else {
            me.displayMaterialList();
        }

    }

    displayMaterialList()
    {
        let me = this;

        me.api.material = "";
        let materials = [""];
        me.materialData.forEach(function (material, id)
        {
            materials.push(material.name);
        });

        materials.sort(function(a,b){
            let id_a = a.split("_")[1];
            let id_b = b.split("_")[1];
            return id_a-id_b;
        });

        me.materialCtrl = me.materialFolder.add( me.api, 'material' ).options( materials );

        me.materialCtrl.onChange( function (value) {
            if(me.api.mesh !== "" && me.api.material !== "")
            {
                let key = me.api.mesh;
                const materialInfo = me.materialData.find(({ name }) => name === me.api.material);
                let data = {[key] : materialInfo}

                me.vue3d.setMaterials(data);
            }
        });
    }

}